import { useSearchParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import PresurveyBody from './PresurveyBody';
import serverip from './Macro';

const Presurvey = () => {
    const [searchParams] = useSearchParams();
    const ticket_no = searchParams.get("cstt");
    const token = searchParams.get("csttoken");

    const [responseData, setResponseData] = useState(null);
    const [loaded, setLoaded] = useState(false);  // For handling the loader
    const [error, setError] = useState(null);  // To handle any errors

    const navigate = useNavigate();

    useEffect(() => {
        if (ticket_no && token && !responseData) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    'ticket_no': ticket_no,
                    'token': token,
                }),
            };

            fetch(`${serverip}/presurvey`, requestOptions)
                .then(response => {
                    if (response.ok) return response.json();
                    throw new Error(response.status);
                })
                .then(data => {
                    setResponseData(data);  // Assuming data holds the required information
                    setLoaded(true);  // Mark as loaded after data is fetched
                })
                .catch(error => {
                    setError(error);
                    console.error(`${error} at /presurvey`, {
                        token,
                        ticket_no,
                    });
                    navigate(`/thankyou`);
                });
        }
    }, [ticket_no, token, responseData, navigate]);  // Dependencies for the useEffect hook

    if (error) {
        return <div>Error occurred. Please try again later.</div>;
    }

    return (
        <>
            <PresurveyBody 
                loaded={loaded} 
                surveyMessage={responseData?.survey_message} 
                surveyTemplateHTML={responseData?.survey_template_html} 
                customerbrandcolor={responseData?.brand_color} 
                customerlogo={responseData?.brand_logo}
                footer_text_color={responseData?.footer_text_color}
                logo_src={responseData?.logo_src}
                lang_message={responseData?.lang_message}
            />
        </>
    );
};

export default Presurvey;
