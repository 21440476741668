import React, { useState, useCallback } from 'react';
import EachQuestionHTML from './eachQuestionHTML';

const QuestionsHTML = ({ survey_questions, survey_templates_map, survey_token, survey_language, lang_message }) => {
    return (
        <div className='qContainer'>
            {survey_questions.map((question) =>
            <div className='question'>
                <div className='questionText'>{ question.translated_question && question.translated_question[survey_language] ? 
                                                question.translated_question[survey_language] : question.question_text }</div>
                <div style={{ display: "flex", justifyContent: 'center' }} id={question.question_id} template_id={question.template_id}>
                    <EachQuestionHTML question={question} template_id={question.template_id} survey_template_map={survey_templates_map} 
                                        survey_token={survey_token} survey_language={survey_language} lang_message={lang_message}/>
                </div>
            </div>
            )}
        </div>
    );
}

export default QuestionsHTML;  