import React from 'react';

const Error = () => {
  return (
    <div className="errorBody">
      <div style={{fontSize: '16vh'}}>Oops!</div>
      <div style={{fontSize: '6vh'}}>Something went wrong.</div>
    </div>
  );
};

export default Error;