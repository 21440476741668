import React, {useState, useEffect} from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Content from './Content';
import Header from './Header';
import Footer from './Footer';
import serverip from './Macro';
import Loader from './Loader';

const PresurveyBody = ({
  loaded,
  surveyMessage,
  surveyTemplateHTML,
  customerbrandcolor,
  customerlogo,
  footer_text_color,
  logo_src,
  lang_message
}) => {
  console.log(surveyTemplateHTML);
  
  return (
    <>
    {loaded ? ( 
        <div className='Layout'>
          <Header customerlogo={customerlogo} customerbrandcolor={customerbrandcolor}/>
          <div className="Body" style={{ padding: '2rem', textAlign: 'center', alignContent: 'center', marginBottom: '10%' }}>
            <span style={{ fontSize: '3rem' }}>{surveyMessage}</span>
            <div dangerouslySetInnerHTML={{ __html: surveyTemplateHTML }} />
          </div>
          <Footer customerbrandcolor={customerbrandcolor} footer_text_color={footer_text_color} logo_src={logo_src} lang_message={lang_message}/>
        </div>
      ) :(
        <><Loader /></>
    )}
    </>
  );
};

export default PresurveyBody;