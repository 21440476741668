import React from "react";
import { SpinningCircleLoader } from "react-loaders-kit";
// import Typed from "react-typed";

const Loader = () => {

  const loaderProps = {
    loading: true,
    duration: 2,
  };

  return (
    <div className="loader" style={{display: 'flex', marginTop: '40vh', justifyContent:'center'}}>
        <SpinningCircleLoader {...loaderProps} />
        {/* <Typed
          className="loader-text"
          strings={["Loading..."]}
          typeSpeed={60}
          backSpeed={0}
        /> */}
    </div>
  );
}

export default Loader;