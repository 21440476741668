import React, { useState, useEffect } from 'react';
import ReasonsHTML from './Content/ReasonsHTML';
import FeedbackHTML from './Content/FeedbackHTML';
import QuestionsHTML from './Content/QuestionsHTML';

const Dataform = ({ headerline, reasonheader, feedbackheader, customerbrandcolor, footer_text_color, lang_message,
    survey_reasons, survey_token, survey_templates_map, survey_questions, survey_language, setview }) => {
    return (
        <div>
            <div className="pageHead">{headerline}</div>

            {
                survey_reasons.length > 0 ?
                <ReasonsHTML survey_reasons={survey_reasons} lang_message={lang_message} reasonheader={reasonheader}
                            survey_token={survey_token} survey_language={survey_language}/> : null
            }
            
            {
                survey_questions.length > 0 ?
                <QuestionsHTML survey_questions={survey_questions} survey_templates_map={survey_templates_map} 
                            survey_token={survey_token} survey_language={survey_language} lang_message={lang_message}/> : null
            }

            <FeedbackHTML feedbackheader={feedbackheader}  lang_message={lang_message} customerbrandcolor={customerbrandcolor} footer_text_color={footer_text_color} survey_token={survey_token} setview={setview}/>
            
        </div>
    );
}


const Thankview = ({lang_message}) => {
    console.log(lang_message);
    return (
        <div className="thankBody">
            <div>{ lang_message.thank_view }</div>
        </div>
    );
}

const Content = ({ headerline, reasonheader, feedbackheader, customerbrandcolor, footer_text_color, lang_message, survey_reasons,
    survey_token, survey_templates_map, survey_questions, survey_language }) => {
    const [view, setView] = useState('dataform');
    return (
        <div className="Body">
            {view === 'dataform' ? <Dataform headerline={headerline} reasonheader={reasonheader} feedbackheader={feedbackheader} 
                                        customerbrandcolor={customerbrandcolor} footer_text_color={footer_text_color} lang_message={lang_message} 
                                        survey_reasons={survey_reasons} survey_token={survey_token} survey_templates_map={survey_templates_map} 
                                        survey_questions={survey_questions} survey_language={survey_language} setview={setView} /> : <Thankview lang_message={lang_message} />}
        </div>
    );
};

export default Content;