import React, { useState } from 'react';
import serverip from '../Macro';

const ReasonsHTML = ({ survey_reasons, lang_message, reasonheader, survey_token, survey_language }) => {
    const [currentreason, setCurrentreason] = useState(null);
    const changeReason = (newReason) => {
        setCurrentreason(newReason);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({'survey_token': survey_token, 'survey_reason': newReason})
        };
        fetch(`${serverip}/survey_data`, requestOptions)
        .then(
            function(response) {
                if (response.ok)
                    return response.json()
                throw new Error(response.status)})
        .catch((error) => {
            console.error(`${error} at /survey_data while saving reasons`, requestOptions);
            navigate(`/error`);
        });
    };

    return (
        <div className='reasonBody'>
            <div className="reasonHead">{reasonheader}</div>
            <div className='reasonList'>
                <select onChange={(event) => changeReason(event.target.value)}
                    value={currentreason || ""}
                    style={{ maxWidth: '80%', fontSize: 'min(3vh, 22px)', fontWeight: 'inherit', color: 'inherit' }}> 
                    <option value="" disabled>{lang_message.select_reasons}</option>
                    {survey_reasons.map((reason) => <option key={reason.survey_reason_unique_id} value={reason.survey_reason_unique_id}>
                        {reason.translated_reason &&  reason.translated_reason[survey_language] ? reason.translated_reason[survey_language] : reason.survey_reason
                        }</option>)}
                </select>
            </div>
        </div>
    );
}

export default ReasonsHTML;