import React from 'react';

const Footer = ({customerbrandcolor, footer_text_color, logo_src, lang_message}) => {
    return (
        <div id="output_footer" className="Footer" style={{backgroundColor:customerbrandcolor,color:footer_text_color}}>
            <span style={{padding:'0.2rem'}}>{lang_message.powered_by}</span>
            <a style={{padding:'0.2rem'}} href="https://csat.ai" target="_blank" rel="noopener noreferrer">
                <img className="Logo-image" id="footer_image" alt="logo" src={logo_src}/>
            </a>
            <span style={{padding:'0.2rem'}}>|</span>
            <a href="https://www.csat.ai/privacy-policy/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline',color:footer_text_color, padding:'0.2rem'}}>{lang_message.privacy_policy}</a>
        </div>
    );
};

export default Footer;