import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Body from './components/Body';
import Prebody from './components/Prebody';
import Presurvey from './components/Presurvey';
import Error from './components/Error';
import Thankyou from './components/Thankyou';
import Unsubscribe from './components/Unsubscribe';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/presurvey" element={<Presurvey/>}></Route>
          <Route path="/survey" element={<Prebody/>}></Route>
          <Route path="/thankyou" element={<Thankyou/>}></Route>
          <Route path="/survey/response" element={<Body/>}></Route>
          <Route path="/unsubscribe" element={<Unsubscribe/>}></Route>
          <Route path="*" element={<Error/>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
