import React, {useState, useEffect} from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Content from './Content';
import Header from './Header';
import Footer from './Footer';
import serverip from './Macro';
import Loader from './Loader';

const Body = () => {
  const [searchParams] = useSearchParams();

  const [loaded, setLoaded] = useState(false);
  const [headerline, setHeaderLine] = useState(null);
  const [reasonheader, setReasonHeader] = useState(null);
  const [feedbackheader, setFeedbackHeader] = useState(null);
  const  [customerlogo, setCustomerLogo] = useState(null);
  const [customerbrandcolor, setBrandColor] = useState(null);
  const [footer_text_color, setFooterTextcolor] = useState(null);
  const[logo_src, setLogoSrc] = useState();
  const [lang_message, setLangMessage] = useState({})
  const[survey_reasons, setSurveyReasons] = useState([]);
  const[survey_templates_map, setSurveyTemplatesMap] = useState({});
  const[survey_questions, setSurveyQuestions] = useState([]);
  const[survey_language, setSurveyLanguage] = useState(null);
  const navigate = useNavigate();
  const survey_token = searchParams.get("id");

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({'token': survey_token})
    };
    fetch(`${serverip}/survey/response`, requestOptions)
    .then(function(response) {
      if (response.ok)
          return response.json()
          throw new Error(response.status)})
    .then(data => {
        const response_json = data.response;
        const lang_message_json = data.response.lang_message;
        setHeaderLine(lang_message_json['title_text']);
        setReasonHeader(lang_message_json['reason_label']);
        setFeedbackHeader(lang_message_json['feedback_label']);
        setLangMessage({comments_placeholder :lang_message_json.comments_placeholder, feedback_label: lang_message_json.feedback_label,
                        powered_by: lang_message_json.powered_by, privacy_policy: lang_message_json.privacy_policy, reason_label :lang_message_json.reason_label,
                        survey_reasons_submit: lang_message_json.survey_reasons_submit, title_text: lang_message_json.title_text, 
                        select_reasons: lang_message_json.select_reasons, thank_view: lang_message_json.thank_view, select_answer: lang_message_json.select_answer});
        setCustomerLogo(`data:image/${response_json.logo_format};base64,${response_json.brand_logo}`);
        setBrandColor(response_json['brand_color']);
        setFooterTextcolor(response_json['footer_text_color']);
        setSurveyReasons(response_json.survey_result);
        setSurveyTemplatesMap(response_json.survey_templates_map);
        setSurveyQuestions(response_json.survey_questions);
        setSurveyLanguage(response_json.survey_language);
        if(response_json['footer_text_color'] === 'white'){
          setLogoSrc('https://static.csat.ai/admin/img/csat-ai-logo-trans-purple-n-med.png');
        } else {
          setLogoSrc('https://static.csat.ai/admin/img/csat-ai-logo-transparent-dark-med.png');
        }
        setLoaded(true);
    }).catch((error) => {
      console.error(`${error} at /survey/response`, requestOptions);
      navigate(`/thankyou`);
    });
  }, []);

  return (
    <>
    {loaded ? ( 
        <div className='Layout'>
        <Header customerlogo={customerlogo} customerbrandcolor={customerbrandcolor}/>
        <Content headerline={headerline} reasonheader={reasonheader} feedbackheader={feedbackheader} customerbrandcolor={customerbrandcolor} 
          footer_text_color={footer_text_color} lang_message={lang_message} survey_reasons={survey_reasons} survey_token={survey_token} 
          survey_templates_map={survey_templates_map} survey_questions={survey_questions} survey_language={survey_language}/>
        <Footer customerbrandcolor={customerbrandcolor} footer_text_color={footer_text_color} logo_src={logo_src} lang_message={lang_message}/>
        </div>
      ) :(
        <><Loader /></>
    )}
    </>
  );
};

export default Body;