import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate} from 'react-router-dom';
import serverip from './Macro';

const Unsubscribe = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || searchParams.get("csttoken");
  const [company_name, setCompanyName] = useState('-');
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          'token': token,
        })
  };
  const navigate = useNavigate();
  
  fetch(`${serverip}/unsubscribe`, requestOptions)
  .then(
      function(response) {
      if (response.ok)
        return response.json()
      throw new Error(response.status)})
  .then(data => {
    setCompanyName(data.company_name)
  })
  .catch((error) => {
      console.error(`${error} at /unsubscribe`, {
        'token': token,
      });
      navigate(`/error`);
  });
  if (company_name != '-')
  return (
    <div className="errorBody">
        <div style={{fontSize: '5vh'}}>You have been successfully unsubscribed from CSAT.AI surveys{company_name}.</div>
    </div>
  );
};

export default Unsubscribe;


