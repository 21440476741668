import { useSearchParams, useNavigate} from 'react-router-dom';
import serverip from './Macro';

const Prebody = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token") || searchParams.get("csttoken");
    const score = searchParams.get("score") || searchParams.get("cstscore");
    const ticket_no = searchParams.get("ticket_no") || searchParams.get("cstticket");
    const current = searchParams.get("current") || searchParams.get("cstcts");
    const option_id = searchParams.get("cstoptionid");
    const key = searchParams.get("cstkey");

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            'token': token,
            'score': score,
            'ticket_no': ticket_no,
            'current': current,
            'answer_option_id': option_id,
            'key': key
          })
    };
    const navigate = useNavigate();
    fetch(`${serverip}/survey`, requestOptions)
    .then(
        function(response) {
        if (response.ok)
            return response.json()
            throw new Error(response.status)})
    .then(data => {
        navigate(`/survey/response?id=${data.survey_token}`)
    })
    .catch((error) => {
        console.error(`${error} at /survey`, {
            'token': token,
            'score': score,
            'ticket_no': ticket_no,
            'current': current,
            'answer_option_id': option_id
          });
        navigate(`/thankyou`);
    });
};

export default Prebody;