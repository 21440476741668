import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import serverip from '../Macro';

const FeedbackHTML = ({ feedbackheader, lang_message, customerbrandcolor, footer_text_color, survey_token, setview}) => {
    const [comment, setComment] = useState("");
    const handleCommentChange = (e) => {
        setComment(e.target.value);
    }
    const handleSubmit = () => {
        setview('thankview');
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'comment': comment, 'survey_token': survey_token })
        };
        fetch(`${serverip}/survey_data`, requestOptions)
        .then(
            function(response) {
                if (response.ok)
                    return response.json()
                throw new Error(response.status)})
        .catch((error) => {
            console.error(`${error} at /survey_data while saving feedback`, requestOptions);
            navigate(`/error`);
        });
    };

    return (
        <div>
            <div className='feedbackHead'>{feedbackheader}</div>
            <textarea className='feedbackComment' placeholder={lang_message.comments_placeholder} onChange={handleCommentChange}></textarea>
            <form style={{ padding: '1rem 0', display: 'flex', justifyContent: 'center' }} onSubmit={handleSubmit}><Button className='buttonClass' style={{ backgroundColor: customerbrandcolor, color: footer_text_color }} type="submit">{lang_message.survey_reasons_submit}</Button></form>
        </div>
    )
}

export default FeedbackHTML;