import React from 'react';

const Thankyou = () => {
    return (
        <div className="thankBody">
            <div>Thank you for the feedback</div>
        </div>
    );
};

export default Thankyou;