import React, { useState } from 'react';
import serverip from '../Macro';

const EachQuestionHTML = ({ question, template_id, survey_template_map, survey_token, survey_language, lang_message }) => {
    const [qsoptid, setQsoptid] = useState(-1);

    const selectAnswer = (question_id, template_id, score, option_id, options_type=0) => {
        setQsoptid(option_id);
        let qsn_json = {};
            if (options_type==3){
                qsn_json[question_id] = [];
                document.querySelectorAll(`input[name="${question_id}"]`).forEach(elem => { 
                    if (elem.checked) {  
                        let data = {    
                            option_id: elem.value,
                            template_id: template_id
                        }
                        qsn_json[question_id].push(data);
                    }
                })
            }
            else
                qsn_json[question_id] = {'template_id': template_id, 'option_id': option_id};
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'answer': qsn_json,
                                    'options_type': options_type,
                                    'survey_token': survey_token })
        };
        fetch(`${serverip}/survey_data`, requestOptions)
        .then(
            function(response) {
                if (response.ok)
                    return response.json()
                throw new Error(response.status)})
        .catch((error) => {
            console.error(`${error} at /survey_data while saving answers`, requestOptions);
            navigate(`/error`);
        });
    };

    const max_optimg_width = template_id && survey_template_map[template_id].type == 0 ? `${90 / (survey_template_map[template_id].config.template_info.length)}vw` : '';

    const getOptImageStyle = (image, q_id, q_template_id, opt_idx, qsoptid) => {
        if (qsoptid !== -1){
            if ([1,2,'1','2'].includes(q_template_id) && image.option_id == qsoptid) {
                return {border: '2px #4e2c78 solid'};
            }
            if (q_template_id == 4){
                if(image.option_id == qsoptid){
                    return {border: '2px #4e2c78 solid'};
                } else {
                    return (opt_idx === 0 ? {border: '1px #aaa solid'} : {border: '1px #aaa solid', borderLeft: 0});
                }
            }
        } else {
            if (q_template_id == 4) {
                return (opt_idx === 0 ? {border: '1px #aaa solid'} : {border: '1px #aaa solid', borderLeft: 0});
            }
        }
        return '';
    }

    const getOptImage = (image, q_id, q_template_id, opt_idx, qsoptid) => {
        if (qsoptid !== -1){
            if (q_template_id == 3 && image.option_id <= qsoptid) {
                return 'https://static.csat.ai/srvy/filled-star.png';
            }
        }
        return image.src;
    }

    if(template_id && survey_template_map[template_id].type == 0){
        return (<div>
                {survey_template_map[template_id].config.template_info.map((image, index) => {
                    const dynamicStyles = getOptImageStyle(image, question.question_id, question.template_id, index, qsoptid);
                    const hardcodedStyles = {maxWidth: max_optimg_width};
                    const final_styles = {...hardcodedStyles, ...dynamicStyles};
                    const final_image = getOptImage(image, question.question_id, question.template_id, index, qsoptid);
                    return (
                        <img key={question.question_id+'_'+index}
                            onClick={() => selectAnswer(question.question_id, question.template_id, image.score, image.option_id)}
                            className="sticky"
                            src={final_image}
                            alt={image.alt}
                            style={final_styles}
                        />
                    );
                })
            }
            </div>
        )
    }

    else if([1,3].includes(question.options_type)){
        const input_type = question.options_type == 1 ? 'radio': 'checkbox';
        const options_array = template_id ? survey_template_map[template_id].config.template_info : 
                                question.translated_options ? question.translated_options.question_options : question.question_options.question_options;
        return (
            <ul className='choices'>
                {options_array.map((option_json) => {
                return (
                    <li>
                        <label><input type={input_type} name={question.question_id} value={option_json.option_id} 
                            onChange={(event) => selectAnswer(question.question_id, question.template_id, -1, event.target.value, question.options_type)}/>
                        <span>{option_json[survey_language] ? option_json[survey_language] : option_json.option_text}</span></label>
                    </li>
                )
            })}
            </ul>
        ) 
    }
    else{
        const options_array = template_id ? survey_template_map[template_id].confg.template_info : 
                                question.translated_options ? question.translated_options.question_options :question.question_options.question_options;
        return (
            <select onChange={(event) => selectAnswer(question.question_id, question.template_id, -1, event.target.value, question.options_type)} 
            style={{ maxWidth: '80%', width:'20rem', fontSize: 'min(3vh, 16px)', fontWeight: 'inherit', color: 'inherit', padding: '5px', marginTop: '10px'}}>
                <option value="" disabled selected style={{display: 'none'}}>{ lang_message['select_answer'] }</option>
                {options_array.map((option_json) => {
                return (
                    <option value={option_json.option_id}>{option_json[survey_language] ? option_json[survey_language] : option_json.option_text}</option>
                )
            })}
            </select>
        )
    }

}

export default EachQuestionHTML;